import axios from 'axios';
import { POST } from '../utilities/AxiosHelpers';

export function getPackagesApi() {
  const url = `https://api.lecturersclub.com/api/v1/admin/packages`;
  const axiosInstance = axios.create();
  return axiosInstance.get(url);
}


/*export function getOrderID(amount) {
  const url = `admin/rozarpay/generate_order_id`;
  const formdata = new FormData();
  formdata.append('amount', amount);
  return POST(url, formdata);
}*/

export function getOrderID(amount, subdomain, mobile, packageID) {
  const url = `https://${subdomain}.api.lecturersclub.com/api/v1/admin/phonePay`;
  const axiosInstance = axios.create();
  const formdata = new FormData();
  formdata.append('amount', amount);
  formdata.append('subdomain', subdomain);
  formdata.append('mobile', mobile);
  formdata.append('packageID', packageID);
  return axiosInstance.post(url, formdata);
}

export function confirmOrder(data) {
  const url = `admin/payment`;
  return POST(url, data);
}