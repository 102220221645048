export const MainNav = [
    {
        icon: 'lnr-chart-bars',
        label: 'Dashboard',
        to: '/dashboard',
    },

    {
        icon: 'lnr-graduation-hat',
        label: 'Students',
        to: '/students',
    },
    {
        icon: 'pe-7s-users',
        label: 'Groups',
        to: '/groups',
    },
    {
        icon: 'pe-7s-notebook',
        label: 'Assignments',
        to: '/assignments',
    },
    {
        icon: 'pe-7s-notebook',
        label: 'Study Material',
        to: '/study-material',
    },
    {
        icon: 'lnr-film-play',
        label: 'Lecture Streams',
        to: '/streams',
    },
    {
        icon: 'lnr-laptop-phone',
        label: 'Online Classes',
        to: '/conferences',
    },

    {
        icon: 'pe-7s-notebook',
        label: 'Quizzes',
        to: '/quiz',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Doubts Received',
        to: '/doubts',
    },
    {
        icon: 'pe-7s-notebook',
        label: 'Send Credentials',
        to: '/messages/students',
    },
    {
        icon: 'lnr-envelope',
        label: 'Reports',
        to: '/reports',
    },
    {
        icon: 'lnr-cog',
        label: 'View Profile',
        to: '/settings',
    },
    {
        icon: 'lnr-cog',
        label: 'Packages',
        to: '/packages',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Technical Support',
        to: '/feedback',
    },
    {
        icon: 'pe-7s-compass',
        label: 'Take A Tour',
        to: '/tour',
    },
];
