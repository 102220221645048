import React, { Component, Fragment } from 'react';
import { Button, Card, CardTitle, CardBody, Form, FormGroup, FormFeedback, Input, Label, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { loginUser } from '../../reducers/AdminAuth';
import { SUBDOMAIN_KEY } from '../../config/constants';
import { requestOtpApi, verifyOtpApi, resetPasswordApi, registerApi } from '../../api/auth_api';
import { getPackagesApi } from '../../api/package_api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../assets/utils/images/Horizontal_Logo.png';

import '../../assets/NewTheme/css/bootstrap.min.css';
import '../../assets/NewTheme/fonts/font-awesome/css/font-awesome.min.css';
import '../../assets/NewTheme/fonts/flaticon/font/flaticon.css';
import '../../assets/NewTheme/css/style.css';

const LOGIN = 'LOGIN';
const REQUEST_OTP = 'REQUEST_OTP';
const VERIFY_OTP = 'VERIFY_OTP';
const RESET_PASSWORD = 'RESET_PASSWORD';
const REGISTER = "REGISTER";


class Login extends Component {
  state = {
    username: '',
    password: '',
    password_confirmation: '',
    mobile: '',
    subdomain: '',
    showPassword: false,
    otp: '',
    token: '',
    screen: LOGIN,
    errorText: "",
    FirstName: "",
    LastName: "",
    InstituteName: "",
    Email: "",
    RegisterPassword: "",
    IdCode: "",
    AppID: "",
    Packages: []
  }

  componentDidMount() {
    const subdomain = localStorage.getItem(SUBDOMAIN_KEY);
    this.setState({ subdomain });
    getPackagesApi().then((res) => {
      this.setState({ Packages: res.data.data })
      console.log(res.data.data);
    })
      .catch((e) => {
        console.log(e.message)
      });
  }

  handleEnterPress(e) {
    if (e.charCode === 13) {
      switch (this.state.screen) {
        case LOGIN:
          this.props.loginUser({ username: this.state.username, password: this.state.password });
          break;
        case REQUEST_OTP:
          this.requestOtp();
          break;
        case VERIFY_OTP:
          this.verifyOtp();
          break;
        case RESET_PASSWORD:
          this.resetPassword();
          break;
        default:
          break;
      }
    }
  }

  setSubdomain(subdomain) {
    localStorage.setItem(SUBDOMAIN_KEY, subdomain);
    this.setState({ subdomain });
  }

  requestOtp() {
    this.setState({ loading: true });
    requestOtpApi({
      mobile_number: this.state.username,
    })
      .then((data) => {
        toast.success(data.message);
        this.setState({ loading: false, screen: VERIFY_OTP, mobile_number: data.mobile_number, password: '' });
      })
      .catch((e) => {
        this.setState({ loading: false });
        if (e.data) {
          if (e.data.insufficient_sms_credits || !e.data.otp_reset_enable) {
            toast.error('OTP password reset disabled. Please contact our support team for assistance');
            return;
          }
        }
        toast.error('Unable to send OTP, check username and try again');
      });
  }

  verifyOtp() {
    this.setState({ loading: true });
    verifyOtpApi({
      mobile_number: this.state.mobile_number,
      otp: this.state.otp,
      call_func: 'generate_forgot_token',
      func_data: {
        email: this.state.username
      },
    })
      .then((res) => {
        toast.success(res.message);
        this.setState({ loading: false, token: res.token, screen: RESET_PASSWORD, otp: '' });
      })
      .catch(() => {
        toast.error('Unable to verify OTP');
        this.setState({ loading: false });
      });
  }

  doRegister() {
    this.setState({ errorText: "" });

    if (this.state.FirstName == "") {
      this.setState({ errorText: "Enter First Name" });
      return;
    }

    if (this.state.InstituteName == "") {
      this.setState({ errorText: "Enter Institute Name" });
      return;
    }

    if (this.state.mobile == "") {
      this.setState({ errorText: "Enter Mobile" });
      return;
    }

    if (this.state.Email == "") {
      this.setState({ errorText: "Enter Email" });
      return;
    }

    if (this.state.RegisterPassword == "") {
      this.setState({ errorText: "Enter Password" });
      return;
    }

    this.setState({ loading: true });
    const organization_name = JSON.stringify({
      organization_name: this.state.InstituteName,
    });

    var IDCode = this.state.FirstName.substring(0, 3);
    var Mobile = this.state.mobile.slice(-3);

    registerApi({
      organization_name,
      username: this.state.Email,
      password: this.state.RegisterPassword,
      password_confirmation: this.state.RegisterPassword,
      mobile: this.state.mobile,
      admin_name: this.state.FirstName + " " + this.state.LastName,
      subdomain: IDCode + Mobile,
      first_name: this.state.FirstName,
      last_name: this.state.LastName,
      affiliate: this.state.AppID
    })
      .then((res) => {
        toast.success("Your Org. Code, Username and Password will be sent to your registered mobile number.");
        this.setState({ loading: false, screen: LOGIN });
      })
      .catch((e) => {
        toast.error(e.message);
        this.setState({ loading: false });
      });
  }

  resetPassword() {
    if (this.state.password === this.state.password_confirmation && this.state.password.length >= 8) {
      this.setState({ loading: true });
      resetPasswordApi({
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
        email: this.state.username,
        token: this.state.token,
      })
        .then((res) => {
          toast.success('Password reset successfully');
          this.setState({ loading: false, screen: LOGIN });
        })
        .catch((e) => {
          toast.error('Unable to Reset Password');
          this.setState({ loading: false });
        });
    } else {
      toast.error('Password and Confirm Password must be same and at least 8 characters long.');
    }
  }

  renderLogin() {
    return (
      <div class="border-0 ml-auto mr-auto login-card">
        <div class="fw-700 display2-md-size mb-3">Login into your account</div>
        {
          (this.state.errorText != "") &&
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            {this.state.errorText}
          </div>
        }

        {
          (this.props.AdminAuth.loginMessage != "") &&
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            {this.props.AdminAuth.loginMessage}
          </div>
        }

        <Form className="mt-4">
          <div className='text-left'>Organization Code</div>
          <div class="form-group mb-3">
            <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" value={this.state.subdomain} onChange={(e) => this.setSubdomain(e.target.value)} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Organization Code" />
          </div>

          <div className='text-left'>Username</div>
          <div class="form-group mb-3">
            <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Username" />
          </div>

          <div className='text-left'>Password</div>
          <div class="form-group icon-input mb-1">

            <i class="font-sm ti-eye text-grey-500 pr-0" onClick={() => this.showHidePassword()} style={{ left: "auto", right: "15px" }}></i>
            <Input class="style2-input pl-5 form-control text-grey-900 font-xss ls-3" type={(!this.state.showPassword) ? 'password' : 'text'} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Password" />
          </div>

          <FormFeedback>Unable to login</FormFeedback>
          <div class="form-check text-left mb-3">
            <input type="checkbox" class="form-check-input mt-2" id="exampleCheck1" />
            <label class="form-check-label font-xsss text-grey-500 mt-1" for="exampleCheck1">Remember me</label>
            <a style={{ cursor: 'pointer' }} class="fw-600 font-xsss text-grey-700 mt-1 float-right" onClick={() => this.setState({ screen: REQUEST_OTP })}>Forgot your Password?</a>
          </div>

        </Form>
        {
          (this.props.AdminAuth.loggingIn) ?
            <Spinner size="lg" color="primary" /> :
            <button onClick={() => this.ValidateLogin()} class="btn btn-primary btn-lg btn-theme">Login</button>
        }

        <div class="extra-login clearfix"></div>

        <p>Don't have an account? <a onClick={() => this.setState({ screen: REGISTER })} href="#" class="thembo text-danger"> Register here</a></p>

      </div>
    );
  }

  ValidateLogin() {
    this.setState({ errorText: "" });
    var subdomain = localStorage.getItem(SUBDOMAIN_KEY);
    if (subdomain == "") {
      this.setState({ errorText: "Please enter Organization Code" });
    } else {
      this.props.loginUser({ username: this.state.username, password: this.state.password });
    }
  }


  renderRequestOtp() {
    return (
      <div class="card shadow-none border-0 ml-auto mr-auto login-card">
        <div class="card-body rounded-0">
          <div class="fw-700 display2-md-size mb-3">Forgot Password</div>

          <Form className="mt-4">

            <div className='text-left'>Organization Code</div>
            <div class="form-group mb-3">
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" value={this.state.subdomain} onChange={(e) => this.setSubdomain(e.target.value)} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Organization Code" />
            </div>

            <div className='text-left'>Username</div>
            <div class="form-group mb-3">
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Username" />
            </div>

          </Form>
          {
            (this.state.loading) ?
              <Spinner size="lg" color="primary" /> :
              <button onClick={() => this.requestOtp()} class="btn btn-primary btn-lg btn-theme">Request OTP</button>
          }

          <div class="form-check text-left mb-3">
            <a style={{ cursor: 'pointer' }} class="fw-600 font-xsss text-grey-700 mt-1 float-right" onClick={() => this.setState({ screen: LOGIN })}>Back To Login</a>
          </div>

        </div>
      </div>
    );
  }

  renderVerifyOtp() {
    return (
      <CardBody>
        <CardTitle className="d-flex flex-row justify-content-center">
          <i
            style={{ fontSize: 20, color: '#343a40', position: 'absolute', left: 6, marginTop: 2, cursor: 'pointer' }}
            className="lnr-arrow-left ml-3"
            onClick={() => this.setState({ screen: REQUEST_OTP })}
          />
          Verify OTP
        </CardTitle>
        <div className="mt-4">
          <FormGroup row>
            <Label className="text-muted">OTP</Label>
            <Input value={this.state.otp} onChange={(e) => this.setState({ otp: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter OTP" />
          </FormGroup>
        </div>
        {
          (this.state.loading) ?
            <Spinner size="lg" color="primary" /> :
            <div className="mt-4 row justify-content-end">
              <div>
                <Button onClick={() => this.verifyOtp()} color="primary">Verify OTP</Button>
              </div>
            </div>
        }
      </CardBody>
    );
  }

  renderResetPassword() {
    return (
      <CardBody>
        <CardTitle className="d-flex flex-row justify-content-center">
          <i
            style={{ fontSize: 20, color: '#343a40', position: 'absolute', left: 6, marginTop: 2, cursor: 'pointer' }}
            className="lnr-arrow-left ml-3"
            onClick={() => this.setState({ screen: REQUEST_OTP })}
          />
          Change Password
        </CardTitle>
        <Form className="mt-4">
          <FormGroup row>
            <Label className="text-muted">Password</Label>
            <Input type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Enter Password" />
          </FormGroup>
          <FormGroup row>
            <Label className="text-muted">Confirm Password</Label>
            <Input type="password" value={this.state.password_confirmation} onChange={(e) => this.setState({ password_confirmation: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} placeholder="Re-Enter Password" />
          </FormGroup>
        </Form>
        {
          (this.state.loading) ?
            <Spinner size="lg" color="primary" /> :
            <div className="mt-4 row justify-content-end">
              <div>
                <Button onClick={() => this.resetPassword()} color="primary">Reset Password</Button>
              </div>
            </div>
        }
      </CardBody>
    );
  }

  MakeIdntificationCode(e) {
    var str = e.target.value;
    str = str.replace(/ /g, '');
    str = str.replace(/[^0-9a-zA-Z ]/g, "");
    this.setState({ FirstName: str });
    var IDCode = str.substring(0, 3);
    var Mobile = this.state.mobile.slice(-3);
    this.setState({ IdCode: "Identification Code : " + IDCode + Mobile })
  }

  setMobile(e) {
    var str = e.target.value;
    str = str.replace(/[^0-9]/g, "")
    this.setState({ mobile: str });
    var IDCode = this.state.FirstName.substring(0, 3);
    var Mobile = e.target.value.slice(-3);
    this.setState({ IdCode: "Identification Code : " + IDCode + Mobile })
  }

  renderRegister() {
    return (
      <div class="card shadow-none border-0 ml-auto mr-auto login-card">
        <div class="card-body rounded-0">
          <div class="fw-700 display2-md-size mb-3">Sign Up</div>

          {
            (this.state.errorText != "") &&
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              {this.state.errorText}
            </div>
          }

          <Form className="mt-4">
            <div class="form-group mb-3">
              <Input class="form-control" placeholder="First Name" value={this.state.FirstName} onChange={(e) => this.MakeIdntificationCode(e)} />
              <div className='text-danger' style={{ fontSize: 12, textAlign: "left" }}>Special characters and space are not allowed</div>
            </div>
            <div class="form-group mb-3">
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="Last Name" value={this.state.LastName} onChange={(e) => {
                var str = e.target.value;
                str = str.replace(/ /g, '')
                str = str.replace(/[^0-9a-zA-Z ]/g, "")
                this.setState({ LastName: str })
              }
              } />
              <div className='text-danger' style={{ fontSize: 12, textAlign: "left" }}>Special characters and space are not allowed</div>
            </div>
            <div class="form-group mb-3">
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" maxLength={10} placeholder="Mobile" value={this.state.mobile} onChange={(e) => this.setMobile(e)} />
            </div>
            <div class="form-group mb-3">
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="Institute's Name" value={this.state.InstituteName} onChange={(e) => {
                var str = e.target.value;
                str = str.replace(/[^0-9a-zA-Z ]/g, "")
                this.setState({ InstituteName: str })}
              }
              />
              <div className='text-danger' style={{ fontSize: 12, textAlign: "left" }}>Special characters and space are not allowed</div>
            </div>
            <div class="form-group mb-3">
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="Email" value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} />
            </div>
            <div class="form-group mb-3">
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="Password" value={this.state.RegisterPassword} onChange={(e) => this.setState({ RegisterPassword: e.target.value })} />
            </div>
            <div class="form-group mb-3">
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="Identification Code" readOnly value={this.state.IdCode} onChange={(e) => this.setState({ IdCode: e.target.value })} />
            </div>
            <div class="form-group mb-3">
              <Input class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="App ID" value={this.state.AppID} onChange={(e) => this.setState({ AppID: e.target.value })} />
            </div>

          </Form>
          {
            (this.state.loading) ?
              <Spinner size="lg" color="primary" /> :
              <button onClick={() => this.doRegister()} class="btn btn-primary btn-lg btn-theme">Register</button>
          }


          <div class="form-check text-left mb-3">
            <a style={{ cursor: 'pointer' }} class="fw-600 font-xsss text-grey-700 mt-1 float-right" onClick={() => this.setState({ screen: LOGIN })}>Back To Login</a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {
          (this.props.AdminAuth.refreshingToken) ?
            <Spinner size="lg" color="primary" /> :
            <div class="login-25">
              <div class="container">
                <div class="row login-box">
                  <div class="col-lg-6 form-section">
                    <div class="form-inner">
                      <a href="" class="logo">
                        <img src={Logo} alt="logo" style={{ height: 60 }} />
                      </a>
                      {this.state.screen === LOGIN && this.renderLogin()}
                      {this.state.screen === REQUEST_OTP && this.renderRequestOtp()}
                      {this.state.screen === VERIFY_OTP && this.renderVerifyOtp()}
                      {this.state.screen === RESET_PASSWORD && this.renderResetPassword()}
                      {this.state.screen == REGISTER && this.renderRegister()}
                    </div>
                  </div>

                  <div class="col-lg-6 align-self-center form-text">
                    <div class="info clearfix align-self-center">
                      <h1 class="animate-charcter">Our Packages</h1>
                      <div className='row'>
                        {
                          this.state.Packages.map((dt, index) => (
                            <div className='col-lg-4 mb-4'>
                              <div className='card'>
                                <div className='card-header'>{dt.name}</div>
                                <div className='card-body'>
                                  No. of Group : {dt.no_of_batches}<br />
                                  No. of Student in Each Group : {dt.no_of_student}<br />
                                  Amount : {dt.amount}<br />
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mt-5 text-center'>
                <a href="https://lecturersclub.com/terms-and-conditions/" className='thembo' style={{ color: "#535353" }} target='_blank'>Terms and Conditions</a>&nbsp;
                |&nbsp;
                <a href="https://lecturersclub.com/privacy-policy/" className='thembo' style={{ color: "#535353" }} target='_blank'>Privacy Policy</a>&nbsp;
                |&nbsp;
                <a href="https://lecturersclub.com/cancellation-policy" className='thembo' style={{ color: "#535353" }} target='_blank'>Cancellation / Refund Policy</a>&nbsp;
                |&nbsp;
                <a href="https://lecturersclub.com/terms-and-conditions/" className='thembo' style={{ color: "#535353" }} target='_blank'>About us</a>&nbsp;
                |&nbsp;
                <a href="https://lecturersclub.com/contact-us/" className='thembo' style={{ color: "#535353" }} target='_blank'>Contact us</a>&nbsp;
                |&nbsp;
                <a href="https://lecturersclub.com/" className='thembo' style={{ color: "#535353" }} target='_blank'>Home Page</a>
              </div>
              </div>
            </div>
        }
      </Fragment>
    );
  }
}

const mapStateToProp = state => ({
  AdminAuth: state.AdminAuth,
});

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (payload) => loginUser(dispatch, payload),
  }
}

export default connect(mapStateToProp, mapDispatchToProps)(Login);